import api from '../../../service/api';

// -- Influencer data
export const getInfluencerDetails = (id, params) => api.get(`/profile/${id}`, { params });
export const getInfluencerPosts = (data) => api.post('/profile/posts', data);
export const getInfluencerPostsInterval = (data) => api.post('/profile/posts/interval', data);
export const getInfluencerCategories = (data) => api.post('/profile/categories', data);
export const getInfluencerInteractions = (data) => api.post('/profile/posts/interactions', data);
export const getInfluencerFollowers = (data) => api.post('/profile/interactions/followers', data);
export const getInteractionsHistory = (data) => api.post('/profile/interactions/history', data);
export const getInfluencerAirscore = (data) => api.post('/profile/interactions/score', data);
export const getSimilarInfluencers = (id) => api.get(`/profile/${id}/similar`); // recomendation
// -- Notes (data = { text: "", profile_id: "" })
export const sendNote = (data) => api.post('/note/create', data);
export const sendPinNote = (id, data) => api.post(`/note/pin/${id}`, data);
export const updNote = (id, data) => api.put(`/note/${id}`, data);
export const removeNote = (id) => api.delete(`/note/${id}`);
export const noteList = (data) => api.post('/note/list/', data);
//
export const sendPostForReclassification = (data) => api.post('/post-reclassification', data);

// -- Demogrpahics
export const getDataset = () => api.get('demographics/dataset');
export const getInfluencerExists = (id, username, network) =>
  api.get(`demographics/${username}/${id}/${network}/exists`);
// export const getDemographicData = () => api.get("demographics/cristiano/58af4944cb903a077437d283")
export const getDemographicData = (id, username, network) =>
  api.get(`demographics/${username}/${id}/${network}`);

export const getDemographicDataUpdate = (id, username, network) =>
  api.get(`demographics/${username}/${id}/${network}/update`);

// -- Influencer card pdf
export const getInfluencerCard = (id) => api.get(`influencer-card/${id}`, { responseType: 'blob' });

// buscar as ultimas planner lists para o modal
