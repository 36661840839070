import { Tooltip } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import hideLikesIcon from '../../../common/assets/icons/hide-likes.png';
import pageErrorImage from '../../../common/assets/images/page-error.png';
import Button from '../../../common/components/Button';
import LineChart from '../../../common/components/Charts/LineChart';
import MultipleLinesChart from '../../../common/components/Charts/MultipleLinesChart';
import Loading from '../../../common/components/Loading';
import Pagination from '../../../common/components/Pagination';
import { selectMenuSlice } from '../../../common/components/SideMenu/slice';
import { buildImgUrl } from '../../../utils';
import { buildValue } from '../../../utils/formatters';
import StateStatus from '../../../utils/status';
import { updateTabTitle } from '../../../utils/updateTabTitle';
import { selectHomeSlice } from '../Home/slice';

import InfluencerFloatingActions from './components/FloatingActions';
import InfluencerHeader from './components/Header';
import InfluencerHeaderFixed from './components/HeaderFixed';
import InteractionChart from './components/InteractionChart';
import PartCategories from './components/PartCategories';
import PartDemographics from './components/PartDemographics';
import PartInteractions from './components/PartInteractions';
import PartMentions from './components/PartMentions';
import PartPosts from './components/PartPosts';
import PartPostsInterval from './components/PartPostsInterval';
import PartSimilarInfluencers from './components/PartSimilarInfluencers';
import {
  clearPosts,
  fetch,
  fetchDataset,
  fetchFollowers,
  fetchInteractions,
  fetchPostsByDate,
  fetchScores,
  selectInfluencerDetailsSlice,
  updateClearInfluencer,
  updateFilterDataType,
  updateSelectedInteractionDay,
} from './slice';

import './index.scss';

const InfluencerCard = ({ byTable, influencerId, onClose }) => {
  const { t } = useTranslation();

  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homeSlice = useSelector(selectHomeSlice);
  const slice = useSelector(selectInfluencerDetailsSlice);
  const menuSlice = useSelector(selectMenuSlice);

  const influencers = homeSlice.results;
  const status = slice.status;
  const indexActive = slice.index;
  const influencer = slice.influencer;
  const dataType = slice.filterDataType;
  const postsByDate = slice.postsByDate;
  const selectedInteractionDay = slice.selectedInteractionDay;

  const [id, updateId] = useState(influencerId || params.id);
  const [selectedFilteredPosts, updateSelectedPostFilter] = useState();
  const [postInInteractionPopup, updatePostInInteractionPopup] = useState(0);

  const dataTypes = [
    { label: 'Dados gerais do influenciador', value: 'general' },
    { label: 'Dados de post publicitário do influenciador', value: 'publi_post' },
  ];
  const interactions = {
    [t('Curtidas')]: 'likes',
    [t('Dislikes')]: 'dislikes',
    [t('Visualizações')]: 'views',
    [t('Comentários')]: 'comments',
    [t('Retweets')]: 'retweets',
    [t('Interações')]: 'interactionsTotal',
  };
  const interactionIcons = {
    [t('posts')]: 'icon-card-posts',
    [t('Curtidas')]: 'icon-like-network',
    [t('Dislikes')]: 'icon-dislike',
    [t('Visualizações')]: 'icon-eye',
    [t('Comentários')]: 'icon-comment-balloon',
    [t('Retweets')]: 'icon-retweets',
    [t('Interações')]: 'icon-comments',
  };

  const changeInfluencer = (action) => {
    const i = action === 'back' ? indexActive - 1 : indexActive + 1;

    if (influencers[i]) {
      dispatch(updateClearInfluencer());

      setTimeout(() => {
        dispatch(updateFilterDataType('general'));

        dispatch(fetch({ id: influencers[i].id, index: i }));

        updateId(influencers[i].id);

        window.history.replaceState(null, `${influencers[i].name}`, `/#/card/${influencers[i].id}`);
      }, 300);
    }
  };

  useEffect(() => {
    if (!byTable && indexActive !== null && influencers.length > 0 && influencers[indexActive]) {
      window.history.replaceState(
        null,
        `${influencers[indexActive].name}`,
        `/#/card/${influencers[indexActive].id}`,
      );

      dispatch(fetch({ id: influencers[indexActive].id, index: indexActive }));
    } else if (id) {
      dispatch(fetch({ id }));
    }
  }, [dataType]);

  useEffect(() => {
    dispatch(fetchDataset());
  }, []);

  useEffect(() => {
    if (status.fetch === StateStatus.loading) {
      window.scrollTo(0, 0);
    }
  }, [status.fetch]);

  updateTabTitle(influencer.name ? `${influencer.name} - AirSearch` : 'AirSearch');

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();

        dispatch(updateSelectedInteractionDay());

        updatePostInInteractionPopup(0);
      }}
      className={`influencer-card background-page card-${
        indexActive !== undefined ? 'popup' : 'page'
      } ${status.fetch === StateStatus.loading && indexActive == undefined ? 'page-loading' : ''}`}
    >
      <div className="influencer-card-container scroll-content">
        {(influencers.length > 0 || params.notificationId) && (
          <div className="container-btn-back">
            <Button
              icon="arrow-back"
              className="outline-light btn-back"
              onClick={() => {
                if (!byTable) navigate(-1);
                else onClose();
              }}
            >
              {t(params.notificationId ? 'Voltar' : 'Voltar à tela inicial')}
            </Button>
          </div>
        )}

        <div className="influencer-card-content">
          {influencer.username && influencer.network && <InfluencerHeader influencerId={id} />}

          {status.fetch === StateStatus.succeeded && (
            <>
              <div className="arrows">
                <i
                  onClick={() => {
                    if (influencers.length > 0 && indexActive > 0) changeInfluencer('back');
                  }}
                  className={`arrow-icon icon icon-arrow-back ${
                    influencers.length > 0 && indexActive > 0 ? 'hover' : 'blocked'
                  }`}
                />

                <i
                  onClick={() => {
                    if (influencers.length > 0 && indexActive + 1 < influencers.length)
                      changeInfluencer('advance');
                  }}
                  className={`arrow-icon icon icon-arrow-next ${
                    influencers.length > 0 && indexActive + 1 < influencers.length
                      ? 'hover'
                      : 'blocked'
                  }`}
                />
              </div>

              <div className="content">
                <InfluencerFloatingActions
                  influencerId={id}
                  influencerNetwork={influencer.network}
                  onSelect={(influencerId) => dispatch(fetch({ id: influencerId }))}
                />

                <InfluencerHeaderFixed />

                <div className="data-types">
                  {dataTypes.map((item) => (
                    <div
                      key={item.value}
                      className={`data-type-option ${
                        dataType === item.value ? 'selected' : 'unselected'
                      }`}
                      onClick={() => {
                        if (item.value === 'publi_post')
                          window.clarity('set', 'PUBLI_VIEWED', true);

                        dispatch(updateFilterDataType(item.value));
                      }}
                    >
                      {item.label}
                    </div>
                  ))}

                  <div
                    className={`underline ${dataType === 'general' ? 'general' : 'publipost'}`}
                  />
                </div>

                <PartInteractions influencerId={id} />

                <PartMentions
                  onClickBrand={(id) => {
                    dispatch(clearPosts());
                    updateSelectedPostFilter({ id, name: 'brandId' });
                  }}
                  onClickPlace={(id) => {
                    dispatch(clearPosts());
                    updateSelectedPostFilter({ id, name: 'placeId' });
                  }}
                  onClickHashtag={(id) => {
                    dispatch(clearPosts());
                    updateSelectedPostFilter({ id, name: 'hashtag' });
                  }}
                />

                {influencer.network !== 'twitch' && <PartDemographics />}

                <PartCategories influencerId={id} />

                {!selectedFilteredPosts && <PartPosts influencerId={id} />}

                <PartSimilarInfluencers influencerId={id} />

                <p className="title-part">{t('Dados do influenciador')}</p>
                <InteractionChart
                  key="followers-chart"
                  title={t('Histórico da evolução do número de seguidores')}
                  isLoading={status.fetchFollowers === StateStatus.loading}
                  onGetData={(days, category) => {
                    dispatch(fetchFollowers({ id, days, category }));
                  }}
                  chart={<LineChart formatType="number" series={influencer.followersChart} />}
                />

                <InteractionChart
                  isScore={true}
                  key="airscore-chart"
                  title={
                    <>
                      {t('Airscore')}
                      {influencer.hiddenLikes && (
                        <div className="profile-hide-like">
                          <img
                            src={hideLikesIcon}
                            alt={t('mão fechada com o polegar levantado e um olho com um risco')}
                          />

                          {t('Oculta curtidas com frequência')}
                        </div>
                      )}
                    </>
                  }
                  categories={influencer.categories}
                  isLoading={status.fetchScores === StateStatus.loading}
                  onGetData={(days, category) => {
                    dispatch(fetchScores({ id, days, category }));
                  }}
                  chart={<MultipleLinesChart formatType="number" series={influencer.scoresChart} />}
                />

                <InteractionChart
                  key="interactions-chart"
                  title={t('Interações da audiência com as publicações do influenciador')}
                  tooltip={
                    <Tooltip
                      overlayClassName="tooltip-network-interactions"
                      title={
                        <>
                          <p className="">{t('Interações por rede')}</p>

                          <div className="junction">
                            <b>Facebook</b>: {t('Comentários, curtidas e visualizações')}
                          </div>
                          <div className="junction">
                            <b>Instagram</b>: {t('Comentários e curtidas')}
                          </div>
                          <div className="junction">
                            <b>Tiktok</b>: {t('Comentários, curtidas e visualizações')}
                          </div>
                          <div className="junction">
                            <b>Twitch</b>: {t('Visualizações')}
                          </div>
                          <div className="junction">
                            <b>Twitter</b>: {t('Comentários, curtidas e retweets')}
                          </div>
                          <div className="junction">
                            <b>Youtube</b>: {t('Comentários, curtidas e visualizações')}
                          </div>
                        </>
                      }
                    >
                      <i className="icon icon-tooltip" />
                    </Tooltip>
                  }
                  categories={influencer.categories}
                  isLoading={status.fetchInteractions === StateStatus.loading}
                  onGetData={(days, category) => {
                    let subCategoriesList = [];
                    const rawInfluencerCategories = influencer.rawCategories;

                    if (category && rawInfluencerCategories && rawInfluencerCategories[category]) {
                      subCategoriesList = rawInfluencerCategories[category].subcategories.map(
                        (item) => item.id,
                      );
                    }

                    dispatch(fetchInteractions({ id, days, categories: subCategoriesList }));
                  }}
                  chart={
                    <div className="box-interactions-chart">
                      {selectedInteractionDay &&
                        selectedInteractionDay.index >= 0 &&
                        selectedInteractionDay.date && (
                          <div
                            className="popup-interactions-data"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <div className="popup-header">
                              <div className="popup-title">
                                {t('Total de posts')}

                                {status.fetchPostsByDate === StateStatus.succeeded && (
                                  <div className="count">{postsByDate.length}</div>
                                )}
                              </div>

                              {buildValue('date2', selectedInteractionDay.date)}
                            </div>

                            <div className="popup-body">
                              {status.fetchPostsByDate === StateStatus.loading && <Loading />}

                              {status.fetchPostsByDate === StateStatus.failed && (
                                <p className="text-error">{t('Erro ao pegar os dados do post')}</p>
                              )}

                              {status.fetchPostsByDate === StateStatus.succeeded && (
                                <>
                                  <div className="part-left">
                                    <i
                                      className={`icon icon-back ${
                                        postsByDate.length < 2 ? 'hidden' : ''
                                      } ${postInInteractionPopup <= 0 ? 'block' : 'hover'}`}
                                      onClick={() => {
                                        updatePostInInteractionPopup(
                                          postInInteractionPopup <= 0
                                            ? 0
                                            : postInInteractionPopup - 1,
                                        );
                                      }}
                                    />

                                    <div className="img-description">
                                      <div className="box-img">
                                        {influencer.network !== 'twitter' &&
                                          postsByDate[postInInteractionPopup] && (
                                            <img
                                              src={buildImgUrl(
                                                influencer.network,
                                                postsByDate[postInInteractionPopup],
                                              ).replace('tiktok.com/', 'www.tiktok.com/')}
                                            />
                                          )}

                                        <i className="icon icon-air-symbol" />

                                        <i
                                          role="button"
                                          className="icon icon-external-link hover"
                                          onClick={() => {
                                            window.open(postsByDate[postInInteractionPopup].link);
                                          }}
                                        />
                                      </div>

                                      {postsByDate.length > 1 && (
                                        <Pagination
                                          pageSize={1}
                                          showArrow={false}
                                          total={postsByDate.length}
                                          current={postInInteractionPopup + 1}
                                          onChange={(p) => {
                                            updatePostInInteractionPopup(p - 1);
                                          }}
                                        />
                                      )}

                                      <div className="description">
                                        <Tooltip
                                          title={
                                            postsByDate[postInInteractionPopup] &&
                                            postsByDate[postInInteractionPopup].description
                                          }
                                        >
                                          {postsByDate[postInInteractionPopup] &&
                                            postsByDate[postInInteractionPopup].description}
                                        </Tooltip>
                                      </div>
                                    </div>

                                    <i
                                      className={`icon icon-advance ${
                                        postsByDate.length < 2 ? 'hidden' : ''
                                      } ${
                                        postInInteractionPopup < postsByDate.length - 1
                                          ? 'hover'
                                          : 'block'
                                      }`}
                                      onClick={() => {
                                        updatePostInInteractionPopup(
                                          postInInteractionPopup < postsByDate.length - 1
                                            ? postInInteractionPopup + 1
                                            : postsByDate.length - 1,
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="part-right">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>{t('Total do post')}</th>
                                          <th>{t('Total do dia')}</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {influencer.interactionChart &&
                                          influencer.interactionChart.map((interaction, i) => {
                                            if (
                                              interaction.name === t('posts') ||
                                              (influencer.network === 'twitch' &&
                                                interaction.name === t('interactions'))
                                            )
                                              return null;

                                            return (
                                              <tr
                                                key={interaction.name}
                                                className={i % 2 == 0 ? 'odd' : 'even'}
                                              >
                                                <td>
                                                  <i
                                                    className={`icon ${
                                                      interactionIcons[interaction.name]
                                                    }`}
                                                  />

                                                  <span className="name">{interaction.name}</span>

                                                  <span className="value">
                                                    {buildValue(
                                                      'number',
                                                      postsByDate[postInInteractionPopup]
                                                        ? postsByDate[postInInteractionPopup][
                                                            interactions[interaction.name]
                                                          ]
                                                        : '',
                                                    )}
                                                  </span>
                                                </td>

                                                <td>
                                                  <i
                                                    className={`icon ${
                                                      interactionIcons[interaction.name]
                                                    }`}
                                                  />

                                                  <span className="name">{interaction.name}</span>

                                                  <span className="value">
                                                    {interaction.data[selectedInteractionDay.index]
                                                      ? buildValue(
                                                          'number',
                                                          interaction.data[
                                                            selectedInteractionDay.index
                                                          ].y,
                                                        )
                                                      : 0}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                      <MultipleLinesChart
                        formatType="number"
                        hasTooltip={true}
                        series={
                          influencer.interactionChart &&
                          influencer.interactionChart.filter(
                            (item) => item.name === t('interactions'),
                          )
                        }
                        onDotOver={({ date, value }) => {
                          setTimeout(() => {
                            const btnPopup = document.querySelector('.button-open-popup');

                            if (btnPopup) {
                              btnPopup.addEventListener('click', (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                const interactionsIndex = influencer.interactionChart.findIndex(
                                  (item) => item.name === t('interactions'),
                                );

                                if (interactionsIndex > -1) {
                                  const index = influencer.interactionChart[
                                    interactionsIndex
                                  ].data.findIndex((item) => item.x === date);

                                  if (index > -1) {
                                    updatePostInInteractionPopup(0);

                                    dispatch(
                                      updateSelectedInteractionDay({
                                        date,
                                        index,
                                      }),
                                    );

                                    dispatch(
                                      fetchPostsByDate({
                                        id: id,
                                        itemsPerPage: value,
                                        date: moment(date).format('yyyy-MM-DD'),
                                      }),
                                    );
                                  }
                                }
                              });
                            }
                          }, 500);
                        }}
                        buildTooltipElement={({ x, y }) => {
                          const interactionsIndex = influencer.interactionChart.findIndex(
                            (item) => item.name === t('interactions'),
                          );

                          if (interactionsIndex > -1) {
                            const index = influencer.interactionChart[
                              interactionsIndex
                            ].data.findIndex((item) => item.x === x);

                            return `<div
                                                    class="popup-interactions-simple-data"
                                                    onclick={(e) {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                    }}
                                                >
                                                    <div class="popup-header">
                                                        ${buildValue('date2', x)}
                                                    </div>

                                                    <div class="popup-body column">
                                                        ${
                                                          influencer.interactionChart &&
                                                          influencer.interactionChart.map(
                                                            (interaction) => {
                                                              if (
                                                                influencer.network === 'twitch' &&
                                                                interaction.name ===
                                                                  t('interactions')
                                                              )
                                                                return "<div class='interaction'></div>";

                                                              return `<div class="interaction" key=${
                                                                interaction.value
                                                              }>
                                                                    <i class="icon ${
                                                                      interactionIcons[
                                                                        interaction.name
                                                                      ]
                                                                    }"></i>

                                                                    <span class="name">${
                                                                      interaction.name
                                                                    }: </span>

                                                                    <span class="value">${
                                                                      interaction.data[0]
                                                                        ? buildValue(
                                                                            'number',
                                                                            interaction.data[index]
                                                                              ?.y,
                                                                          )
                                                                        : 0
                                                                    }</span>
                                                                </div>`;
                                                            },
                                                          )
                                                        }

                                                        ${
                                                          y != 0
                                                            ? `<button class="ant-btn button button-open-popup">
                                                                ${t('Abrir dados por post')}
                                                            </button>`
                                                            : ''
                                                        }
                                                    </div>
                                                </div>`;
                          }

                          return '';
                        }}
                      />

                      {influencer.interactionsChartTotal &&
                        Object.keys(influencer.interactionsChartTotal).length > 0 && (
                          <div className="chart-interactions-description">
                            <div className="total-values">
                              <p className="title-value">{t('Total do gráfico acima')}:</p>

                              <div className="values">
                                {influencer.interactionsChartTotal &&
                                  Object.keys(influencer.interactionsChartTotal)
                                    .filter((item) => item !== 'posts' && item !== 'interactions')
                                    .map((interaction) => (
                                      <div className="value" key={interaction}>
                                        {t(interaction)}:{' '}
                                        <b>
                                          {buildValue(
                                            'number',
                                            influencer.interactionsChartTotal[interaction],
                                          )}
                                        </b>
                                      </div>
                                    ))}
                              </div>
                            </div>

                            <div className="total-values">
                              <p className="title-value">{t('Média de interações')}:</p>

                              <div className="values">
                                {influencer.interactionsChartAverage &&
                                  Object.keys(influencer.interactionsChartAverage)
                                    .filter((item) => item !== 'posts' && item !== 'interactions')
                                    .map((interaction) => (
                                      <div className="value" key={interaction}>
                                        {t(interaction)}:{' '}
                                        <b>
                                          {buildValue(
                                            'number',
                                            (
                                              influencer.interactionsChartAverage[interaction] || 0
                                            ).toFixed(2),
                                          )}
                                        </b>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  }
                />

                <PartPostsInterval influencerId={id} />

                <br />
                <br />
              </div>
            </>
          )}

          {status.fetch === StateStatus.loading && <Loading />}

          {status.fetch === StateStatus.failed && (
            <div className="feedback-error">
              <img
                src={pageErrorImage}
                alt={t(
                  'um homem e duas mulheres trabalhando com seus notebooks sentados em um notebook gigante',
                )}
              />

              <span>{t('Poxa, não conseguimos carregar esta página')}.</span>

              <span>
                <b>{t('Por favor, tente novamente mais tarde')}.</b>
              </span>
            </div>
          )}
        </div>
      </div>

      {selectedFilteredPosts && selectedFilteredPosts.id && selectedFilteredPosts.name && (
        <div
          className={`modal-mention-posts ${
            menuSlice.collapsed ? 'collapsed' : ''
          } modal-mention-posts-${selectedFilteredPosts ? 'opened' : 'closed'}`}
          onClick={() => updateSelectedPostFilter(null)}
        >
          <div
            className="mention-posts-card-content"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <PartPosts
              filterId={selectedFilteredPosts.id}
              filterName={selectedFilteredPosts.name}
            />
          </div>
        </div>
      )}
    </div>
  );
};

InfluencerCard.defaultProps = {
  byTable: false,
  onClose: () => {},
};

InfluencerCard.propTypes = {
  index: PropTypes.any,
  byTable: PropTypes.bool,
  onClose: PropTypes.func,
  influencerId: PropTypes.string,
  influencers: PropTypes.arrayOf(PropTypes.object),
};

export default InfluencerCard;
