import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
// 
import { useDispatch, useSelector } from "react-redux"
import { fetchNotification, selectSolicitationSlice, fetchSolicitationInfluencers } from "./slice"
import { updateClearInfluencer, updateIndex } from "../InfluencerCard/slice"
import StateStatus from "../../../utils/status"
// 
import Button from "../../../common/components/Button"
import Loading from "../../../common/components/Loading"
import Pagination from "../../../common/components/Pagination"
import InfluencerRow from "../../../common/components/InfluencerRow"
import Tabs from "../../../common/components/Tabs"
// 
import "./index.scss"

const Solicitation = () => {
  const { id } = useParams()
  // 
  const { t } = useTranslation()
  // 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //
  //
  const slice = useSelector(selectSolicitationSlice)
  // 
  const notification = slice.notification
  // 
  const status = slice.status
  const statusInfluencers = slice.statusInfluencers
  //
  const [selectedTab, updateSelectedTab] = useState("complete")
  // 
  const columns = [
    { key: "name", title: t("Dados da conta"), span: 2 },
    { key: "network", title: t("Redes Sociais"), span: 2 },
    { key: "followers", title: t("Seguidores"), span: 2 },
    { key: "airscore", title: t("Airscore"), span: 2 },
    { key: "engagementRate", title: t("Engajamento"), span: 2 },
    { key: "themes", title: t("Categorias"), span: 6 },
    { key: "planner-list", title: t("Lista de Planejamento"), span: 2 },
  ]

  useEffect(() => {
    if (id) {
      dispatch(fetchNotification(id))
    }
  }, [id])

  useEffect(() => {
    if (status === StateStatus.succeeded) {
      dispatch(fetchSolicitationInfluencers({ id, page: 1, status: selectedTab }))
    }
  }, [status, selectedTab])

  return <div className="solicitation-page background-page scroll-content">
    <div className="solicitation-content">

      {status === StateStatus.loading && <Loading />}

      <div>
        {status === StateStatus.failed && <>
          <br />
          <br />
          <p>{t("Erro. Tente novamente mais tarde")}</p>
        </>}

        {status === StateStatus.succeeded && <>
          <div className="container-btn-back">
            <Button
              icon="arrow-back"
              className="outline-light btn-back"
              onClick={() => navigate("/")}
            >
              {t("Ir à tela inicial")}
            </Button>
          </div>

          <p className="title">
            {t("Solicitação")}: {notification.title || t("adicionar influenciador")}
          </p>

          <p className="date">
            <b>{t("Data de solicitação")}:</b> {notification.date}
          </p>

          {notification.plannerList && <p className="planner-list">
            <b>{t("Lista")}:</b> {notification.plannerList}
          </p>}

          {/*  */}

          <p className="subtitle">{t("Perfis solicitados")}:</p>

          <span className="description">{t(notification.description)}</span>

          <Tabs
            tabWidth={153}
            tabs={[
              { label: t("Adicionados"), value: "complete", count: notification.completeCount },
              { label: t("Recusados"), value: "not_complete", count: notification.notCompleteCount },
            ]}
            selected={selectedTab}
            onSelect={(value) => updateSelectedTab(value)}
          />

          {statusInfluencers === StateStatus.succeeded && <>
            {selectedTab === "complete" && <table>
              <thead>
                <tr>
                  {columns.map((item, key) => {
                    return <th
                      key={key}
                      rowSpan={item.span}
                    >
                      {item.title}
                    </th>
                  })}
                </tr>
              </thead>

              <tbody>
                {notification.influencers && notification.influencers.map((influencer, key) => {
                  return <InfluencerRow
                    key={key}
                    influencer={influencer}
                    onSelect={() => {
                      dispatch(updateIndex(-1))
                      dispatch(updateClearInfluencer())

                      setTimeout(() => {
                        navigate(`/card/${influencer.id}/${notification.id}`)
                      }, 300);
                    }}
                  />
                })}
              </tbody>
            </table>}

            {selectedTab !== "complete" &&
              <div className="influencers-declined">
                {notification.influencers && notification.influencers.map((influencer, key) => {
                  return <div className="influencer-card-error" key={key}>
                    <div className="tag-box">
                      <span className={`tag tag-${influencer.status}`}>{t(influencer.status)}</span>
                    </div>

                    <div className="values">
                      <p className="link"><b>{t("Link do influenciador")}:</b> {influencer.profileUrl}</p>

                      {influencer.status === "error" && 
                        <p className="text-error">
                          {influencer.message || t("Infelizmente não conseguimos adicionar este perfil.")}

                          {/* <span
                            className="hover"
                            role="button"
                            onClick={() => {
                              dispatch(changeObjAlert({
                                show: true,
                                type: "info",
                                title: t("Perfil não adicionado"),
                                subtitle: t("Infelizmente não conseguimos adicionar este perfil."),
                                text: <>
                                  <span>{t("Alguma situações impedem nosso sistema de fazer está ação")}:</span>

                                  <br />

                                  <ul>
                                    <li>{t("O perfil é privado")}.</li>
                                    <li>{t("O perfil não é comercial")}.</li>
                                    <li>{t("O perfil não existe mais ou trocou o nome de usuário.")}.</li>
                                  </ul>
                                </>,
                                cancelText: t("Fechar"),
                                onCancel: () => dispatch(showAlert(false)),
                              }))
                            }}
                          >
                            {t("Entenda")}
                          </span> */}
                        </p>
                      }
                    </div>
                  </div>
                })}

                {notification.influencers && notification.influencers.length < 1 && <p>{t("Sem influenciadores")}</p>}
              </div>
            }

            {slice.totalPages > 1 &&
              <Pagination
                pageSize={10}
                current={slice.page}
                total={slice.influencersTotal}
                isDisabled={statusInfluencers === StateStatus.loading || statusInfluencers === StateStatus.failed}
                onChange={(p) => {
                  dispatch(fetchSolicitationInfluencers({
                    id,
                    page: p,
                    status: selectedTab
                  }))
                }}
              />
            }
          </>}

          {statusInfluencers === StateStatus.loading && <Loading />}
          {statusInfluencers === StateStatus.failed && <p className="text-error">{t("Erro ao pegar os perfis")}</p>}
        </>}
      </div>
    </div>
  </div>
}

export default Solicitation
